<template>
  <form-modal
    :title="_title"
    ref="formmodal"
    @cancel="cancel"
    :btnCancelAble="false"
    textSave="Copy"
    size="sm"
    @save="clickCoppy"
  >
    <b-row>
      <input
        type="text"
        class="form-control"
        aria-describedby="helpId"
        placeholder=""
        readonly
        v-model="_value"
        ref="clone"
        id="myInput"
        name="myInput"
      />
    </b-row>
  </form-modal>
</template>
<script>
export default {
  data() {
    return { datas: {} }
  },
  computed: {
    _title() {
      try {
        return this.datas.text
      } catch (error) {
        return ""
      }
    },
    _value() {
      try {
        return this.datas.value
      } catch (error) {
        return ""
      }
    },
  },
  methods: {
    clickCoppy() {
      var _value = this.$refs.clone
      _value.innerHTML = window.location.href
      console.log(_value.innerHTML)
      _value.select()
      document.execCommand("copy")

      this.alert.success({ title: `Copy`, text: this.$refs.clone.value })
      this.switch("hide")
    },
    show(data) {
      this.datas = data
      this.switch("show")
    },

    cancel() {
      this.switch("hide")
    },

    switch(type) {
      type === "show"
        ? this.$refs.formmodal.show()
        : this.$refs.formmodal.hide()
    },
  },
}
</script>
